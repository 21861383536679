import React, { useEffect } from 'react';
import '../App.css';

function Planets() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="planets">
        <h1>An Overview of Planets in Vedic Astrology</h1>

        <div style={{ margin: '20px 0' }}>
                    {/* Planets1 */}
                    <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-1752350757192424"
                        data-ad-slot="4969848238"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
        </div>

        <p>
          In Vedic astrology, the planets play a crucial role in shaping an individual's life and destiny. These celestial bodies are not just seen as mere objects in the sky but as significant influencers that impact various aspects of our lives, including personality, career, relationships, and more. Each planet represents certain energies and characteristics, and their placement in a natal chart reveals much about a person's traits and life events.
        </p>

        <h2>The Nine Planets</h2>
        <p>
          Vedic astrology primarily considers nine planets, also known as grahas, which include the Sun (Surya), Moon (Chandra), Mars (Mangal), Mercury (Budha), Jupiter (Guru or Brihaspati), Venus (Shukra), Saturn (Shani), Rahu (North Node of the Moon), and Ketu (South Node of the Moon).
        </p>

        <ul className="enlarged-text">
          <li>
            <strong>Sun (Surya):</strong> The Sun represents the soul, vitality, and ego. It symbolizes authority, power, and one's sense of self. A well-placed Sun in a birth chart can indicate strong willpower, leadership qualities, and good health. The Sun rules the sign of Leo and is exalted in Aries.
          </li>
          <li>
            <strong>Moon (Chandra):</strong> The Moon governs the mind, emotions, and intuition. It's associated with motherhood, nurturing, and the subconscious. The Moon's placement and aspects in a chart can reveal much about an individual's emotional nature and instinctive responses. It rules Cancer and is exalted in Taurus.
          </li>
          <li>
            <strong>Mars (Mangal):</strong> Mars represents energy, courage, and action. It's associated with passion, aggression, and physical strength. A well-placed Mars can indicate drive and determination, while a poorly placed one might lead to impulsiveness or conflict. Mars rules Aries and Scorpio and is exalted in Capricorn.
          </li>
          <li>
            <strong>Mercury (Budha):</strong> Mercury governs communication, intellect, and analytical abilities. It's associated with learning, commerce, and adaptability. Mercury's influence can be seen in one's thinking patterns and communication style. It rules Gemini and Virgo and is exalted in Virgo.
          </li>
          <li>
            <strong>Jupiter (Guru or Brihaspati):</strong> Jupiter is known as the great benefic, representing wisdom, expansion, and good fortune. It's associated with higher learning, philosophy, and spirituality. A strong Jupiter can bring opportunities for growth and abundance. It rules Sagittarius and Pisces and is exalted in Cancer.
          </li>
          <li>
            <strong>Venus (Shukra):</strong> Venus governs love, beauty, and pleasure. It's associated with relationships, artistic talents, and material comforts. Venus' placement can indicate one's approach to romance and aesthetics. It rules Taurus and Libra and is exalted in Pisces.
          </li>
          <li>
            <strong>Saturn (Shani):</strong> Saturn represents discipline, responsibility, and karmic lessons. Often viewed as a malefic planet, Saturn's influence can bring challenges but also opportunities for growth through hard work and perseverance. It rules Capricorn and Aquarius and is exalted in Libra.
          </li>
          <li>
            <strong>Rahu (North Node of the Moon):</strong> Rahu is not a physical planet but a mathematical point where the Moon's orbit crosses the ecliptic plane. It represents worldly desires, ambition, and material growth. Rahu's influence can be both deceptive and innovative.
          </li>
          <li>
            <strong>Ketu (South Node of the Moon):</strong> Ketu, the South Node, is Rahu's counterpart. It represents spirituality, detachment, and past-life influences. Ketu's placement can indicate areas where one might experience sudden changes or spiritual insights.
          </li>
        </ul>

        <h2>Planetary Relationships and Conditions</h2>
        <p>
          In Vedic astrology, planets interact with each other and can be in various states of strength or weakness based on their positions and relationships. Some key concepts include:
        </p>
        <ul className="enlarged-text">
          <li>
            <strong>Exaltation and Debilitation:</strong> Planets are considered strongest in their exaltation signs and weakest in their debilitation signs.
          </li>
          <li>
            <strong>Friendship and Enmity:</strong> Planets have natural friendships and enmities with each other which can affect their influence in a chart.
          </li>
          <li>
            <strong>Combustion:</strong> Planets too close to the Sun are said to be combust, which can weaken their influence.
          </li>
          <li>
            <strong>Retrograde Motion:</strong> When planets appear to move backward from Earth's perspective, they are considered retrograde, which can alter their effects.
          </li>
          <li>
            <strong>Aspects:</strong> Planets cast aspects on other houses and planets, influencing them from a distance.
          </li>
          <li>
            <strong>Conjunctions:</strong> When planets occupy the same sign or house, their energies combine, creating complex effects.
          </li>
        </ul>
        <p>
          The interpretation of planetary positions and relationships forms the core of Vedic astrological analysis. Astrologers consider these factors along with the birth chart's overall structure to provide insights into an individual's life path, personality, and potential challenges and opportunities.
        </p>
        <p>
          It's important to note that while Vedic astrology provides a framework for understanding cosmic influences, it's ultimately a tool for self-reflection and personal growth. The planets' energies are seen as influences rather than deterministic forces, allowing for the role of free will and personal development in shaping one's life.
        </p>

        <h2>Benefic and Malefic Planets</h2>
        <p>
          In Vedic astrology, planets are categorized as benefic (favorable) or malefic (unfavorable) based on their inherent nature and the effects they generally produce:
        </p>
        <ul className="enlarged-text">
          <li>
            <strong>Benefic Planets:</strong> Jupiter, Venus, Mercury, and the Moon. These planets typically bring positive influences such as growth, harmony, and prosperity.
          </li>
          <li>
            <strong>Malefic Planets:</strong> Saturn, Mars, Rahu, and Ketu. These planets are often associated with challenges, obstacles, and difficult lessons. However, their influence is necessary for growth and transformation.
          </li>
        </ul>

        <h2>Planetary Friendship and Enmity</h2>
        <p>
          The relationship between planets, known as planetary friendship and enmity, is crucial in Vedic astrology. This relationship affects how planets interact with each other in a natal chart, influencing the overall outcome. The relationships between planets significantly impact how they influence an individual's life. These relationships are categorized into friendships, enmities, and neutral stances, which can alter the outcomes of various planetary configurations in a birth chart. Understanding these relationships helps astrologers provide more accurate readings and predictions.
        </p>
        <ul className="enlarged-text">
          <li><strong>Friendly Planets:</strong> Planets that support and enhance each other's positive qualities.</li>
          <li><strong>Enemy Planets:</strong> Planets that create friction and diminish each other's beneficial effects.</li>
        </ul>
        <img src="/planetary_friends_table.png" alt="Planetary Friends Table" style={{ width: '50%', marginBottom: '20px' }} />
        
        <h2>Importance of Planetary Friendships and Enmities</h2>
        <p>
          These relationships influence the way planetary energies manifest in an individual's life. Friendly planets support each other's positive qualities, enhancing overall well-being and success. Conversely, enemy planets create friction, leading to challenges and obstacles that require careful management. For example, a favorable aspect or conjunction between friendly planets can enhance positive outcomes in the areas they govern, such as wealth, health, and relationships. On the other hand, difficult aspects between enemy planets can bring about conflicts, delays, and hardships.
        </p>

        <div style={{ margin: '20px 0' }}>
                    {/* Planets2 */}
                    <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-1752350757192424"
                        data-ad-slot="8546131161"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
        </div>
        
        <h2>Favorable and Unfavorable Houses and Signs</h2>
        <img src="/planetary_info_table.png" alt="Planetary Info Table" style={{ width: '65%', marginBottom: '10px' }} />
        <p>
          In Vedic astrology, the placement of planets in different houses and signs of the zodiac significantly influences their effects on an individual's life. Some placements are considered auspicious, enhancing the planet's positive attributes, while others can amplify challenges and negative influences. Understanding these placements is crucial for interpreting a natal chart effectively.
        </p>

        <h2>Planetary Periods (Dashas)</h2>
        <p>
          The concept of Dashas, or planetary periods, is unique to Vedic astrology. Dashas indicate the periods in an individual's life when certain planets exert their influence most strongly. Each Dasha is ruled by a specific planet and lasts for a particular duration, shaping the events and experiences during that time. In Vedic astrology, the concept of Dashas is fundamental to understanding the timing of events and the overall influence of planets in an individual's life. Dashas are planetary periods that indicate which planets are most active at different times in a person's life, thus shaping their experiences and outcomes. The most commonly used Dasha system is the Vimshottari Dasha, which has a cycle of 120 years and covers all nine planets (Navagrahas).
        </p>

        <h2>The Structure of Vimshottari Dasha</h2>
        <p>
          The Vimshottari Dasha system divides the 120-year cycle into periods ruled by each of the nine planets. Each planet governs a specific number of years within this cycle:
        </p>
        <ul className="enlarged-text">
          <li><strong>Ketu:</strong> 7 years</li>
          <li><strong>Venus (Shukra):</strong> 20 years</li>
          <li><strong>Sun (Surya):</strong> 6 years</li>
          <li><strong>Moon (Chandra):</strong> 10 years</li>
          <li><strong>Mars (Mangal):</strong> 7 years</li>
          <li><strong>Rahu:</strong> 18 years</li>
          <li><strong>Jupiter (Guru):</strong> 16 years</li>
          <li><strong>Saturn (Shani):</strong> 19 years</li>
          <li><strong>Mercury (Budha):</strong> 17 years</li>
        </ul>
        <p>
          These periods are not the same for everyone and depend on the position of the Moon at the time of birth. The starting point of the Dasha is determined by the Nakshatra (lunar mansion) in which the Moon is placed at birth.
        </p>

        <h2>Mahadasha and Antardasha</h2>
        <ul className="enlarged-text">
          <li>
            <strong>Mahadasha:</strong> The major period of a planet. It sets the overall tone for the specific period of life, bringing the influence of the ruling planet to the forefront.
          </li>
          <li>
            <strong>Antardasha:</strong> Sub-periods within the Mahadasha. Each Mahadasha is divided into sub-periods governed by all the planets, modifying the results of the Mahadasha.
          </li>
        </ul>
        <p>
          For example, during the Mahadasha of Venus, one might experience different phases influenced by the Antardashas of other planets, like Venus-Sun, Venus-Moon, and so on.
        </p>

        <h2>Effects of Dashas</h2>
        <p>
          The effects of Dashas are determined by several factors, including:
        </p>
        <ul className="enlarged-text">
          <li><strong>Placement of the Dasha Lord:</strong> The house and sign where the Dasha lord is placed in the natal chart.</li>
          <li><strong>Aspects and Conjunctions:</strong> The relationships and interactions with other planets.</li>
          <li><strong>Nakshatra:</strong> The lunar mansion in which the Dasha lord is placed.</li>
          <li><strong>Strength and Dignity:</strong> Whether the planet is exalted, debilitated, or in its own sign.</li>
        </ul>

        <h2>Interpreting Dashas</h2>
        <p>
          The interpretation of Dashas involves analyzing the natal chart and understanding how the Dasha lord interacts with other elements in the chart. Here are some general guidelines:
        </p>
        <ul className="enlarged-text">
          <li><strong>Exalted or Own Sign:</strong> If the Dasha lord is exalted or in its own sign, it generally brings favorable results.</li>
          <li><strong>Friendly Sign:</strong> Placement in a friendly sign can bring moderate to good results.</li>
          <li><strong>Enemy Sign:</strong> Placement in an enemy sign may bring challenges and obstacles.</li>
          <li><strong>Debilitated:</strong> A debilitated Dasha lord often brings difficulties, but these can be mitigated by other favorable factors in the chart.</li>
        </ul>

        <h2>Example of Dasha Analysis</h2>
        <p>
          Consider a person whose Moon is placed in the Nakshatra of Magha at birth, ruled by Ketu. Their life would start with the Mahadasha of Ketu. The experiences during this period would be heavily influenced by Ketu's placement and relationships in the natal chart. After the Ketu Mahadasha, the person would move into the Venus Mahadasha, bringing a significant shift in focus and experiences. The specific effects would depend on Venus's placement and strength in the chart.
        </p>
      </section>
    </main>
  );
}

export default Planets;
