import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Ascendants from './components/Ascendants';
import Nakshatras from './components/Nakshatras';
import Intro from './components/Intro';
import SideMenu from './components/SideMenu';
import Houses from './components/Houses';
import Signs from './components/Signs';
import Moon from './components/Moon';
import Sun from './components/Sun';
import Mercury from './components/Mercury';
import Mars from './components/Mars';
import Venus from './components/Venus';
import Jupiter from './components/Jupiter';
import Saturn from './components/Saturn';
import Rahu from './components/Rahu';
import Ketu from './components/Ketu';
import Conjunctions from './components/Conjunctions';
import Aspects from './components/Aspects';
import Planets from './components/Planets';
import Retrogrades from './components/Retrogrades';
import Privacy from './components/Privacy';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="main-content">
          <SideMenu />
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/intro" element={<Intro />} />
              <Route path="/ascendants" element={<Ascendants />} />
              <Route path="/nakshatras" element={<Nakshatras />} />
              <Route path="/houses" element={<Houses />} />
              <Route path="/signs" element={<Signs />} />
              <Route path="/moon" element={<Moon />} />
              <Route path="/sun" element={<Sun />} />
              <Route path="/mercury" element={<Mercury />} />
              <Route path="/mars" element={<Mars />} />
              <Route path="/venus" element={<Venus />} />
              <Route path="/jupiter" element={<Jupiter />} />
              <Route path="/saturn" element={<Saturn />} />
              <Route path="/rahu" element={<Rahu />} />
              <Route path="/ketu" element={<Ketu />} />
              <Route path="/conjunctions" element={<Conjunctions />} />
              <Route path="/aspects" element={<Aspects />} />
              <Route path="/planets" element={<Planets />} />
              <Route path="/retrogrades" element={<Retrogrades />} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
