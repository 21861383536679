import React, { useEffect } from 'react';
import '../App.css';

function Houses() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="houses">
        <h1>Vedic Astrology Houses: An Introduction</h1>

        <div style={{ margin: '20px 0' }}>
                    {/* Houses1 */}
                    <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-1752350757192424"
                        data-ad-slot="8181468964"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
        </div>

        <p>
          In Vedic Astrology, the astrological chart is divided into 12 houses, each representing different areas of life. These houses provide a detailed insight into various aspects of an individual's experiences and personality. Each house has specific significations and is associated with particular planets and zodiac signs. Understanding the significance of houses is fundamental for interpreting an astrological chart accurately.
        </p>
        <h2>The Structure of a Birth Chart</h2>
        <p>
          We will be focusing on the North Indian style birth chart. This style, also known as the diamond chart, divides the chart into houses which represent various aspects of human experience. Each house is associated with different areas of life such as personality, wealth, siblings, and more. The sample chart shown below has the houses explicitly labeled for demonstration purposes. Typically, houses are not labeled in a Vedic birth chart. Each house spans 30 degrees and is considered most effective at the degree occupied by the Ascendant point. The strength and impact of a planet depend on its placement within a house.
        </p>
        <img src="/north_indian_chart.png" alt="North Indian Chart" style={{ width: '700px', height: 'auto', margin: '5px 0' }} />
        
        <h2>Groupings of Houses</h2>
        <p>Houses are categorized as angular (kendra), trinal (trikona), dusthana (difficult), and upachaya (increasing).</p>
        <p><strong>Angular (Kendras) Houses:</strong> 1st, 4th, 7th, and 10th houses form the foundation of a chart, providing strength and motivation.</p>
        <img src="/kendra_houses.png" alt="Kendra Houses" style={{ width: '500px', height: 'auto', margin: '5px 0' }} />
        
        <p><strong>Trinal (Trikonas) Houses:</strong> 1st, 5th, and 9th houses are considered auspicious, representing fortune and blessings.</p>
        <img src="/trikona_houses.png" alt="Trikona Houses" style={{ width: '500px', height: 'auto', margin: '5px 0' }} />
        
        <p><strong>Dusthana Houses:</strong> 6th, 8th, and 12th houses are challenging, indicating obstacles and difficulties.</p>
        <img src="/dusthana_houses.png" alt="Dusthana Houses" style={{ width: '500px', height: 'auto', margin: '5px 0' }} />
        
        <p><strong>Upachaya Houses:</strong> 3rd, 6th, 10th, and 11th houses improve over time, indicating growth and betterment through effort.</p>
        <img src="/upachaya_houses.png" alt="Upachaya Houses" style={{ width: '500px', height: 'auto', margin: '5px 0' }} />
        
        <p><strong>Maraka Houses:</strong> 2nd and 7th houses can affect longevity.</p>
        <img src="/maraka_houses.png" alt="Maraka Houses" style={{ width: '500px', height: 'auto', margin: '5px 0' }} />
        
        <div style={{ margin: '20px 0' }}>
                    {/* Houses2 */}
                    <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-1752350757192424"
                        data-ad-slot="6658334424"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
        </div>
        
        <h2>The 12 Houses of Vedic Astrology</h2>
        <h3>1. First House (Lagna Bhava/Ascendant)</h3>
        <p>Associated with Aries and Mars</p>
        <p>Karaka (significator) planet: Sun</p>
        <p>Key Themes: Self-awareness, vitality, personal outlook, beginnings.</p>
        <p>
          Additional info: The first house, also known as the Ascendant, is the cornerstone of an astrological chart. It signifies the individual's identity, physical appearance, and overall health. The sign and planets located in this house influence how one perceives the world and how others perceive them. The first house is crucial in determining the person's basic temperament and life path.
        </p>
        
        <h3>2. Second House (Dhana Bhava)</h3>
        <p>Affinity with Taurus and Venus</p>
        <p>Karaka planet: Jupiter</p>
        <p>Key Themes: Finances, family, speech, values.</p>
        <p>
          Additional info: The second house is associated with material wealth, possessions, and values. It also covers speech and family life. The strength of the second house can indicate financial prosperity and the individual's attitude towards wealth and security. Additionally, it reflects the quality of one's communication and early family environment.
        </p>
        
        <h3>3. Third House (Bratru Bhava)</h3>
        <p>Connected to Gemini and Mercury</p>
        <p>Karaka planet: Mars</p>
        <p>Key Themes: Communication, siblings, courage, short journeys.</p>
        <p>
          Additional info: The third house represents communication, siblings, short travels, and courage. It highlights the individual's skills in expressing ideas and maintaining relationships with siblings and close relatives. This house also denotes one's ability to take initiative and face challenges.
        </p>
        
        <h3>4. Fourth House (Sukha Bhava)</h3>
        <p>Affinity with Cancer and Moon</p>
        <p>Karaka planets: Moon and Mercury</p>
        <p>Key Themes: Home, mother, emotional security, property.</p>
        <p>
          Additional info: The fourth house is linked to home, mother, inner peace, and property. It reflects the individual's emotional foundation and sense of security. The fourth house governs matters related to one's home environment, real estate, and the nurturing received from the mother or maternal figures.
        </p>
        
        <h3>5. Fifth House (Putra Bhava)</h3>
        <p>Connected to Leo and Sun</p>
        <p>Karaka planet: Jupiter</p>
        <p>Key Themes: Creativity, children, romance, education.</p>
        <p>
          Additional info: The fifth house deals with creativity, children, romance, and education. It signifies one's creative talents, intellectual pursuits, and capacity for enjoyment. The presence and condition of planets in this house can indicate the potential for offspring and romantic inclinations.
        </p>
        
        <h3>6. Sixth House (Satru Bhava)</h3>
        <p>Affinity with Virgo and Mercury</p>
        <p>Karaka planets: Mars and Saturn</p>
        <p>Key Themes: Health, service, enemies, daily work.</p>
        <p>
          Additional info: The sixth house covers health, daily routines, enemies, and service. It reveals how one handles obstacles, illnesses, and routine responsibilities. This house also reflects the individual's attitude towards service and their interactions with subordinates or adversaries.
        </p>
        
        <h3>7. Seventh House (Kalatra Bhava)</h3>
        <p>Connected to Libra and Venus</p>
        <p>Karaka planet: Venus</p>
        <p>Key Themes: Partnerships, marriage, public relationships.</p>
        <p>
          Additional info: The seventh house is associated with partnerships, marriage, and public relationships. It highlights one's approach to committed relationships and business partnerships. The planets in this house can indicate the nature of the spouse and the dynamics of marital relationships.
        </p>
        
        <h3>8. Eighth House (Ayur Bhava)</h3>
        <p>Affinity with Scorpio and Mars</p>
        <p>Karaka planet: Saturn</p>
        <p>Key Themes: Transformation, shared resources, secrets, death and rebirth.</p>
        <p>
          Additional info: The eighth house represents transformation, death, rebirth, and shared resources. It delves into the deeper, often hidden aspects of life such as secrets, inheritance, and the subconscious mind. This house is also linked to occult sciences and mystical experiences.
        </p>
        
        <h3>9. Ninth House (Bhagya Bhava)</h3>
        <p>Connected to Sagittarius and Jupiter</p>
        <p>Karaka planets: Jupiter and Sun</p>
        <p>Key Themes: Higher education, philosophy, travel, spirituality.</p>
        <p>
          Additional info: The ninth house is connected to higher education, philosophy, long-distance travel, and spirituality. It signifies the individual's quest for knowledge, ethical values, and belief systems. This house also covers religious practices and the influence of mentors or spiritual guides.
        </p>
        
        <h3>10. Tenth House (Karma Bhava)</h3>
        <p>Affinity with Capricorn and Saturn</p>
        <p>Karaka planets: Mercury, Sun, Jupiter, and Saturn</p>
        <p>Key Themes: Career, public image, ambitions.</p>
        <p>
          Additional info: The tenth house governs career, public image, and ambitions. It shows the individual's professional path and achievements. The position and strength of planets in this house can indicate the level of success and recognition one might attain in their career.
        </p>
        
        <h3>11. Eleventh House (Labha Bhava)</h3>
        <p>Connected to Aquarius and Saturn</p>
        <p>Karaka planet: Jupiter</p>
        <p>Key Themes: Friendships, social networks, aspirations, gains.</p>
        <p>
          Additional info: The eleventh house relates to friendships, social networks, and gains. It reflects one's aspirations, social circle, and the ability to achieve goals. This house also covers support received from friends and the community.
        </p>
        
        <h3>12. Twelfth House (Vyaya Bhava)</h3>
        <p>Affinity with Pisces and Jupiter</p>
        <p>Karaka planet: Saturn</p>
        <p>Key Themes: Isolation, spirituality, hidden talents, endings.</p>
        <p>
          Additional info: The twelfth house deals with isolation, losses, hidden talents, and spirituality. It represents the end of cycles and introspection. This house is associated with the individual's connection to the divine and their inner spiritual journey.
        </p>
        
        <h2>Additional Concepts</h2>
        <p>
          <strong>Bhavat Bhavam:</strong> Deriving secondary meanings by counting houses from a specific house.
        </p>
        <p>
          <strong>House Karakas:</strong> Planets that act as general significators for each house.
        </p>
        
        <h2>When interpreting a Vedic astrology chart, it's essential to consider:</h2>
        <p>1) The strength of the house lord.</p>
        <p>2) The condition of the house significator (karaka).</p>
        <p>3) Influences on the house from occupying or aspecting planets.</p>
      </section>
    </main>
  );
}

export default Houses;
