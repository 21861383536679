import React, { useEffect } from 'react';
import '../App.css';

function Aspects() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="aspects">
        <h1>Aspects in Vedic Astrology</h1>

        <div style={{ margin: '20px 0' }}>
                    {/* Aspects1 */}
                    <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-1752350757192424"
                        data-ad-slot="1709244164"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
        </div>
        
        <p>In Vedic astrology, aspects are a fundamental concept that significantly influence the interpretation of a birth chart. An aspect occurs when one planet "looks" at another planet or house, exerting its influence and modifying the effects of the other planet or the house it aspects. This concept is crucial for understanding the dynamics of a horoscope and predicting events in a person's life.</p>

        <h2>Importance of Aspects</h2>
        <p>Aspects are essential because they can alter the inherent qualities and outcomes associated with planets and houses. While conjunctions (when two planets are in the same house) are commonly known, aspects provide a deeper level of analysis. They help explain certain events and behaviors that conjunctions alone cannot clarify. For instance, a positive conjunction may be influenced negatively by a harsh aspect from another planet, changing the overall outcome.</p>

        <h2>Types of Aspects</h2>
        <p>Each planet has specific aspects. In Vedic astrology, aspects are straightforward compared to Western astrology, where aspects include terms like trine, square, and sextile, which can be confusing. In Vedic astrology, aspects are generally whole sign aspects, meaning that a planet in one sign aspects specific other signs according to fixed rules.</p>

        <h2>Planetary Aspects</h2>
        <ul className="enlarged-text">
          <li><strong>Sun, Moon, Mercury, Venus:</strong> These planets aspect the 7th house from where they are located.</li>
          <li><strong>Mars:</strong> Aspects the 4th, 7th, and 8th houses from its position.</li>
          <li><strong>Jupiter:</strong> Aspects the 5th, 7th, and 9th houses from its position.</li>
          <li><strong>Saturn:</strong> Aspects the 3rd, 7th, and 10th houses from its position.</li>
          <li><strong>Rahu and Ketu:</strong> Aspect the 5th, 7th, and 9th houses from their positions.</li>
        </ul>

        <img src="/aspects_table.png" alt="Aspects Table" style={{ width: '700px', marginBottom: '10px' }} />
        <p>Some planets have what are known as special aspects, which are additional to their regular 7th house aspect. Mars, Jupiter, and Saturn have special aspects which are particularly significant in chart analysis.</p>

        <h2>Mars' Special Aspects</h2>
        <ul className="enlarged-text">
          <li><strong>4th house:</strong> Represents Mars' aggressive and energetic influence on domestic affairs.</li>
          <li><strong>7th house:</strong> Shows direct confrontation and partnerships.</li>
          <li><strong>8th house:</strong> Indicates intensity, transformation, and possibly conflict in matters related to the 8th house.</li>
        </ul>

        <h2>Jupiter's Special Aspects</h2>
        <ul className="enlarged-text">
          <li><strong>5th house:</strong> Enhances intelligence, creativity, and education.</li>
          <li><strong>7th house:</strong> Impacts relationships, partnerships, and balance in life.</li>
          <li><strong>9th house:</strong> Expands wisdom, higher learning, and spiritual growth.</li>
        </ul>

        <h2>Saturn's Special Aspects</h2>
        <ul className="enlarged-text">
          <li><strong>3rd house:</strong> Focuses on efforts, communication, and siblings.</li>
          <li><strong>7th house:</strong> Affects long-term commitments, partnerships, and responsibilities.</li>
          <li><strong>10th house:</strong> Influences career, status, and professional achievements.</li>
        </ul>

        <h2>Mutual Aspects</h2>
        <p>Mutual aspects occur when two planets aspect each other simultaneously. This creates a dynamic exchange of energies, enhancing the influence of both planets involved. Mutual aspects can lead to significant life events and transformations as the energies of the planets are in constant interaction.</p>

        <h2>Orb of Influence</h2>
        <p>The degree to which a planet aspects another can vary. While the traditional approach considers whole sign aspects, some astrologers use orbs, which are degrees within which the aspect is considered effective. Typically, an orb of 5-10 degrees is used, with closer aspects having a stronger influence.</p>

        <h2>Practical Implications of Aspects</h2>
        <p>Aspects modify the results of houses and planets. For example, Saturn aspecting a house can bring delays, restrictions, and a need for discipline in the matters related to that house. Jupiter's aspect, on the other hand, can bring growth, wisdom, and positivity. Understanding these influences helps in making accurate predictions and offering remedies.</p>
      </section>
    </main>
  );
}

export default Aspects;
