import React from 'react';
import '../App.css';

function About() {
  return (
    <main>
      <section id="about">
        <h1>About Us</h1>
        <p>Welcome to our Vedic Astrology website. This website was made to save people hundreds of hours of exploration and money by giving them the most important bits of information they might be curious about. It is not meant to be comprehensive but it serves to provide the most common answers to questions individuals have about their Vedic birth charts and Vedic Astrology in general.</p>
        <h2>Discovering Vedic Astrology</h2>
        <p>It is a well-known fact that the vast majority of people are only aware of the Western Astrology Sun sign. The truth is many do not even understand the intricacies of Western Astrology. Imagine how unfamiliar Vedic Astrology might be to the average person? Once discovered, the journey to learning Vedic Astrology can be quite challenging, and this is where our website comes in. Our mission is to give you a head start on your path to discovering Vedic Astrology.</p>
        <h2>What You will Find Here</h2>
        <p>Upon gaining exposure to Vedic Astrology, there are a few common elements most people immediately wonder about their birth charts. These common themes often take a lot of time to understand. Most people are too busy to invest this much time in exploring such a deep topic. Sometimes the information available may be incomplete or purposefully hidden to sell consultations, reports, or other services.</p>
        <h2>Our Mission</h2>
        <p>Our website is designed to save you time and money by providing the essential information you're likely to be curious about. We strive to make Vedic Astrology more accessible and easier to understand for everyone. We believe in sharing knowledge openly and freely, ensuring that you have access to the most relevant and valuable insights into Vedic Astrology.</p>
        <h2>Continuous Growth</h2>
        <p>Our website is continually growing, and we are committed to adding more interesting content over time. If there is a specific topic you want to see discussed, or if you have any suggestions, comments, or complaints, please feel free to reach out using the contact form below. Your feedback is invaluable in helping us improve and expand our content to better serve you.</p>
        <p>Thank you for visiting our website. We hope you find the information here helpful and enlightening on your journey to understanding Vedic Astrology.</p>
        <h2>Contact Us</h2>
        <p>If you have any questions, suggestions, or feedback, please use the form below to get in touch with us. We look forward to hearing from you!</p>
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSeAKRgIxi2xhf_LvJKv1zkHNykgRXNS_KVCVT-Y51WDcRgdsQ/viewform?embedded=true" 
          width="100%" 
          height="850" 
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
          title="Feedback Form">
          Loading…
        </iframe>
      </section>
    </main>
  );
}

export default About;
