import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <Link to="/">
          <img src="/header_image.png" alt="Header" className="header-image" />
        </Link>
      </div>
    </header>
  );
}

export default Header;
